import AdminLayout from '@/Layouts/AdminLayout.vue'
import resetStore from '@/Stores/Plugins/resetStore'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, { type BugsnagPluginVueResult } from '@bugsnag/plugin-vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import mitt from 'mitt'
import { createPinia } from 'pinia'
import 'tippy.js/dist/tippy.css'
import { type ComponentInstance, computed, createApp, type DefineComponent, h } from 'vue'
import VueTippy from 'vue-tippy'
import { ZiggyVue } from 'ziggy-js'
import '../css/admin.css'

declare const __BUILD_DATE__: string

const hasBugsnag = !!import.meta.env.VITE_BUGSNAG_API_KEY
const isProduction = process.env.NODE_ENV === 'production'
if (isProduction && !hasBugsnag) {
    console.warn('Bugsnag API key is missing')
}

if (hasBugsnag) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        appType: 'admin',
        appVersion: __BUILD_DATE__,
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: [ 'production', 'staging' ],
        onError: function (event) {
            const auth = computed(() => usePage().props.auth)
            const user = auth.value?.user ?? false
            if (user) {
                event.setUser(user.id.toString(), user.email, user.full_name)
            }
        }
    })
}

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
    progress: {
        delay: 250,
    },
    resolve: (name: string) => {
        let page = resolvePageComponent(`./PagesAdmin/${name}.vue`, import.meta.glob<DefineComponent>('./PagesAdmin/**/*.vue'))
        page.then((module: ComponentInstance<any>) => {
            module.default.layout = module.default.layout || AdminLayout
        })

        return page
    },
    setup({ el, App, props, plugin }) {
        const VueApp = createApp({ render: () => h(App, props) })

        if (hasBugsnag) {
            VueApp.use(Bugsnag.getPlugin('vue') as BugsnagPluginVueResult)
        }

        const pinia = createPinia()
        pinia.use(resetStore)

        VueApp.use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .use(VueTippy, {
                directive: "tippy",
                defaultProps: {
                    placement: 'bottom',
                    allowHTML: true,
                    delay: [500, null],
                },
            })
            .provide('csrf_token', props.initialPage.props.csrf_token)
            .provide('auth', props.initialPage.props.auth)
            .provide('emitter', mitt())
            .mount(el)
    },
})
